@font-face {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Basier/SemiBold.eot');
  src:
    local('Basier Circle SemiBold'),
    local('/fonts/Basier/SemiBold'),
    url('/fonts/Basier/SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Basier/SemiBold.woff2') format('woff2'),
    url('/fonts/Basier/SemiBold.woff') format('woff'),
    url('/fonts/Basier/SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/Basier/Medium.eot');
  src:
    local('Basier Circle Medium'),
    local('/fonts/Basier/Medium'),
    url('/fonts/Basier/Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Basier/Medium.woff2') format('woff2'),
    url('/fonts/Basier/Medium.woff') format('woff'),
    url('/fonts/Basier/Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Basier Circle';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/Basier/Regular.eot');
  src:
    local('Basier Circle Regular'),
    local('/fonts/Basier/Regular'),
    url('/fonts/Basier/Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Basier/Regular.woff2') format('woff2'),
    url('/fonts/Basier/Regular.woff') format('woff'),
    url('/fonts/Basier/Regular.ttf') format('truetype');
}
