@import 'styles/includes';

.container {
  display: flex;
  flex-direction: column;
  column-gap: gutter(0.5);

  .label {
    grid-area: label;
    font-size: 16px;

    &[aria-required='true']::after {
      display: inline-block;
      margin-left: 0.25rem;
      color: red;
      content: '*';
    }
  }

  select {
    padding-right: 2rem !important;
    appearance: none !important;
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g><path d='M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z' fill='currentcolor' fill-opacity='0.8'/></g></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
  }

  .input {
    grid-area: input;
    border-radius: 3px;

    &.native {
      padding: 0.5rem;
      border: 1px solid color(ash);
    }

    &.material {
      height: min-content;
      padding: 0.7rem;
      color: color(battleship-grey);
      background-color: color(pearl);
      border: none;
      border-radius: 4px;
      outline: none;
    }

    &[data-verified='false'] {
      border-color: color(ui-red);
    }

    &:focus {
      border-color: color(cerulean);
    }
  }

  .error-message {
    grid-area: error;
    min-height: 24px;
    font-size: 14px;
    color: color(ui-red);

    &[aria-hidden='true'] {
      display: none;
      visibility: hidden;
    }
  }

  &.checkbox {
    display: grid;
    grid-template: 'input label' 1fr / auto 1fr;

    .label {
      font-size: 14px;
    }

    .error-message {
      display: none;
    }
  }
}
