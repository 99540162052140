@import 'styles/includes';

.container {
  width: 100%;
  min-height: 100vh;
  padding: 4rem 0;
  background: color(pearl);

  .inner {
    @include keep-within-width;

    .card {
      background: white;

      &.left {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 4rem;
        border-radius: 0.5rem 0.5rem 0 0;

        > * + * {
          margin-top: gutter(2);
        }

        @include breakpoint-md {
          border-radius: 0.5rem 0 0 0.5rem;
        }
      }

      &.right {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
        background: radial-gradient(50% 50% at 50% 50%, rgba(2 136 174 / 71%) 0, #0288ae 100%);
        border-radius: 0 0 0.5rem 0.5rem;

        @include breakpoint-md {
          border-radius: 0 0.5rem 0.5rem 0;
        }

        .image {
          width: 100%;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: $gutter;
        align-items: center;
        width: 100%;

        .input {
          width: 100%;
        }

        .buttons {
          display: flex;
          flex-direction: column;
          gap: $gutter;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          margin-top: 0;

          @include breakpoint-lg {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }

          .remember-me,
          .forgot {
            font-size: 14px;
            line-height: 1.5;
            color: color(battleship-grey);
          }
        }

        .error {
          text-align: center;
        }

        .show-password {
          position: relative;
        }

        .eye {
          position: absolute;
          top: 36px;
          right: 18px;
          z-index: 10;
          cursor: pointer;
        }
      }

      .submit {
        align-self: center;
      }
    }
  }

  .icon {
    height: 1rem;
  }
}
