@import 'variables';

@mixin breakpoint-xs {
  @media screen and (width >= 375px) {
    @content;
  }
}

@mixin breakpoint-sm {
  @media screen and (width >= 540px) {
    @content;
  }
}

@mixin breakpoint-md {
  @media screen and (width >= 720px) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media screen and (width >= 960px) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media screen and (width >= 1216px) {
    @content;
  }
}

@mixin breakpoint($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin keep-within-width($padding: true) {
  @if $padding {
    padding-right: $gutter;
    padding-left: $gutter;
  }
  margin-right: auto;
  margin-left: auto;

  @include breakpoint-xs {
    max-width: $breakpoint-xs;
  }

  @include breakpoint-sm {
    max-width: $breakpoint-sm;
  }

  @include breakpoint-md {
    max-width: $breakpoint-md;
  }

  @include breakpoint-lg {
    max-width: $breakpoint-lg;
  }

  @include breakpoint-xl {
    max-width: $breakpoint-xl;
  }
}

@mixin full-width {
  padding-right: calc(0px + (100vw - 100%) / 2);
  padding-left: calc(0px + (100vw - 100%) / 2);
  margin-right: calc(0px - (100vw - 100%) / 2);
  margin-left: calc(0px - (100vw - 100%) / 2);

  // overflow-x: hidden;
}

// Can be used in combination with full-width
@mixin margin-vertical($value) {
  margin-top: $value;
  margin-bottom: $value;
}

@function gutter($amount: 1) {
  @return $gutter * $amount;
}
