$breakpoint-xs: 375px;
$breakpoint-sm: 540px;
$breakpoint-md: 720px;
$breakpoint-lg: 960px;
$breakpoint-xl: 1216px;

$breakpoints: (
  'xs': $breakpoint-xs,
  'sm': $breakpoint-sm,
  'md': $breakpoint-md,
  'lg': $breakpoint-lg,
  'xl': $breakpoint-xl,
);

$gutter: 16px;

$font-regular: 'Basier Circle';
